import React, { useMemo, useState } from "react";
import styles from "./style.module.scss";
import { Breadcrumb } from "../../../../common/Parts";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import {
  APP_PATHS,
  APP_ROLES,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "../../../../common/components";
import { ReactComponent as ViewIcon } from "../../../../assests/svg/view_icon.svg";
import { ReactComponent as DownloadIcon } from "../../../../assests/svg/download.svg";
import AddAssessmentByAdmin from "../../../../common/Parts/ModalPopups/AddAssessmentByAdmin";

import { Spinner } from "react-bootstrap";
import CustomizedMenus from "../../../../common/components/MenuList";

const AssessmentDashboard = () => {
  const navigate = useNavigate();

  const [addAssessmentPopup, setAddAssessmentPopup] =
    React.useState<boolean>(false);
    const [isLoading , setIsLoading] = useState(true);
  const [isDownloadPdf, setDownloadPdf] = useState(false);
  const [downloadPdfData, setDownloadPdfData] = useState(null);
  const [clickedAssessmentId, setClickedAssessmentId] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const itemsPerPage = 10;

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });

  let { data: organizationData } = useApiQuery({
    queryKey: RQ_KEYS.USER_ORGANIZATION,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_USER_ORGANIZATION}?user_org_id=${
      userAuthData && userAuthData.user_org_id > 0
        ? userAuthData.user_org_id
        : null
    }`,
    enabled: !!userAuthData,
  });

  organizationData = organizationData && {
    ...organizationData,
    location: organizationData.branch_locations,
  };

  let { data: assessments }: any = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENTS_BY_ORG_ID_CATEGORIZED,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_ASSESSMENT_BY_ORG_ID_CATEGORIZED}`,
  });

  console.log("Org data in assessments", assessments);

  const { mutate: getAssessmentPdfData } = _useMutation({
    queryKey: RQ_KEYS.ASSESSMENT_PDF_DATA,
    onSuccess: (data: any) => {
      setIsLoading(false);
      setDownloadPdf(true);
      setDownloadPdfData(data.data);
      console.log("PDF DATA=-=-=-=-", data);
      
    },
  });
    const handleAssessmentClick = (assessment_id: string) => {
    if (
      userAuthData?.role === APP_ROLES.ORG_ADMIN ||
      userAuthData?.role === APP_ROLES.BRANCH_ADMIN ||
      userAuthData?.role === APP_ROLES.VENDOR_USER ||
      userAuthData?.role === APP_ROLES.ORG_USER ||
      userAuthData?.role === APP_ROLES.AUDITOR
    ) {
      navigate(
        `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
          assessment_id
        )} `
      );
    }
    //  else if (userAuthData?.role === APP_ROLES.VENDOR_USER) {
    //   navigate(APP_PATHS.ASSIGNED_ASSESSMENTS, {
    //     state: { selectedAssessmentId: assessment_id },
    //   });
    // }
  };

const assignedToChild = useMemo(()=>{
if(userAuthData?.role === APP_ROLES.AUDITOR){
      return userAuthData.user_invited_by;
}
else {
  return userAuthData?.user_id
}
},[userAuthData])
console.log("userAuthData>>>>>",userAuthData);

  const handleDownloadAssessmentPdf = async (assessment_id: string) => {
    setIsLoading(true);
    getAssessmentPdfData({
      url: API_ROUTE.GET_PDF_ASSESSMENT_DATA,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        assessment_id: assessment_id,
        assigned_by_user_id:assignedToChild,
        role: userAuthData?.role,
        userOrgId: userAuthData?.user_org_id,
      },
    });
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAssessments = assessments?.data?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil((assessments?.data?.length || 0) / itemsPerPage);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function ShowUserProfile() {
    // console.log(userAuthData)
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS.USER_LOGIN_DETAILS);
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div
          className={`manageAssessmentContainer ${styles.manageAssessmentContainer}`}
        >

          {userAuthData?.role === APP_ROLES.ORG_ADMIN && (
            <div className={styles.assessmentheader}>
              <Breadcrumb pageName="Assessments" />
              <Button
                id="add-assessment"
                label="Add Assessment"
                padding="8px 10px"
                onClick={() => setAddAssessmentPopup(true)}
                // Icon={<PlusIcon fill="#ffffff" className="svg_image_icon" />}
                border="2px solid var(--secondaryColor)"
                background="transparent"
                color="var(--secondaryColor)"
              />
            </div>
          )}
          <div className={styles.manageAssessmentBody}>
            <div className={styles.dashbrdVendorContainer}>
              {!loadingState ? (
                <>
                  <Table
                    tableHeaders={[
                      "Assessment Name",
                      "Region",
                      "Country",
                      "Assessment Category",
                      "Assessment Status",
                      "Last Modified",
                    ]}
                    tableData={
                      currentAssessments &&
                      currentAssessments.map((item: any) => ({ ...item }))
                    }
                    hideColumns={[
                      // "archived",
                      "assessment_id",
                      // "assessment_creater_id",
                      // "assessment_industry",
                      // "assessment_jurisdiction_country",
                      // "assessment_jurisdiction_state",
                      // "assessment_location",
                      // "archived_date",
                      // "publish_status",
                    ]}
                    action={{
                      name: "Action",
                      buttonOne: (data: any) => (
                        // <Tooltip text="View" rightSide={true}>
                        <Button
                          // background=" var(--primaryColor)"
                          onClick={() =>
                            handleAssessmentClick(data?.assessment_id)
                          }
                          gap="10px"
                          label="View"
                          id="1"
                          Icon={
                            <ViewIcon
                              fill="#ffffff"
                              className={`svg_image_icon ${styles.viewSvg}`}
                            />
                          }
                          // fill="#000000"
                          // className="svg_image_icon"
                        />
                        // <a style={{ fontSize: "13px" }} href="View">
                        //   View
                        // </a>
                        // </Tooltip>
                      ),
                      buttonTwo: (data: any) => (
                        <>

                          <CustomizedMenus
                            onClick={() => {
                          
                              setClickedAssessmentId(data?.assessment_id);
                              handleDownloadAssessmentPdf(data?.assessment_id);
                            }}
                            isDownloadPdf={
                              isDownloadPdf &&
                              clickedAssessmentId === data?.assessment_id
                            }
                            pdfData={downloadPdfData}
                            // disableCondition={isLoading}
                            disableCondition={isLoading}
                            actions={[
                              {
                                label: "Export PDF",
                                icon: <DownloadIcon fill="var(--whiteColor)"/>,
                                condition: isLoading,
                                onClick: () => {
                                  
                                  console.log("Pdf Downloaded",isLoading);
                                  setClickedAssessmentId(data?.assessment_id);
                                  // handleDownloadAssessmentPdf(
                                  //   data?.assessment_id
                                  // );
                                },
                              },
                            ]}
                        
                          />
                        </>
                      ),
                    }}
                  />
                </>
              ) : (
                <div className={styles.assesmentLoader}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </div>
            {assessments?.data?.length <= 0 && (
              <div style={{ textAlign: "center", margin: "30px" }}>
                No Assessments Assigned
              </div>
            )}
          </div>
        </div>
        {itemsPerPage < 10 && (
          <div className={styles.paginationControls}>
            <Button
              id="1"
              label="Previous"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            />
            <span>{` ${currentPage}`}</span>
            <Button
              id="2"
              label="Next"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            />
          </div>
        )}
        
      </div>
      <AddAssessmentByAdmin
        heading="Add Assessment"
        onClickCancel={() => setAddAssessmentPopup(false)}
        openState={addAssessmentPopup}
        data={organizationData}
        assignedAssessmentIds={assessments?.assignedAssessmentIds}
      />
    </>
  );
};

export default AssessmentDashboard;

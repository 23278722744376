import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import { Breadcrumb } from "../../../../common/Parts";
import { Button, Dropdown, MultiSelect } from "../../../../common/components";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import {
  APP_PATHS,
  APP_ROLES,
  COMPLIANCE_STATUSES,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import LoadingHoc from "../../../../common/components/LoadingHoc";
import ExportButton from "./ExportButton";
import DateRangePickerComp from "./DateRangePicker";
import { addMonths } from "date-fns";
// import { useLocation } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  capitalizeFirstLetter,
  getAdminRoleFromKey,
} from "../../../../utils/helpers";

const ReportsPage = () => {
  const [params] = useSearchParams();

  const [assessmentsOfOrg, setAssessmentsOfOrg] = useState("");
  const [reportData, setReportData] = useState("");
  const [enableDownloadButton, setEnableDownloadButton] = useState(false);
  const [noDataError, setNoDataError] = useState(false);
  // const [assessmentName, setAssessmentName] = useState("");
  const [assessmentData, setAssessmentData] = useState<{
    name: string;
    value: string;
  } | null>(null);

  const [reqData, setReqData] = useState<any>({});

  // console.log("reqdata !!11!!!1! >>", reqData?.compliance_status?.value);

  const [reportType, setReportType] = useState([]);


const [dynamicReportType , setDynamicReportType] = useState<string>('')
console.log("dynamicReportType>>>>>",dynamicReportType)
  const [showDateRange, setShowDateRange] = useState(false);

  const [dateRangeState, setDateRangeState] = useState([
    {
      startDate: params.has("startDate")
        ? new Date(params.get("startDate") as string)
        : addMonths(new Date(), -12),
      endDate: params.has("endDate")
        ? new Date(params.get("endDate") as string)
        : new Date(),
      key: "selection",
    },
  ]);

  const [showGenerateButton, setShowGenerateButton] = useState(false);

  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [owner, setOwner] = useState<string>("");
  const [owners, setOwners] = useState([]);
const [location , setLocation] = useState<string>('');
const [compaliance , setCompaliance] = useState<string>("");
  const [orgLocations, setOrgLocations] = useState([]);

  // const location = useLocation();

  // console.log("location in reports", location);

  let allOrganizations: any = useApiQuery({
    queryKey: RQ_KEYS.ORGANIZATIONS,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ORGANIZATIONS,
  });

  const userData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  let organizations: any = [];

  organizations.push(_getQueryState({ key: RQ_KEYS.USER_ORGANIZATION }));
  console.log("organizations =>", organizations);

  console.log("all Organizations =>", allOrganizations);

  console.log("userData :>>>>>> " + userData?.role);
  // all org dropdown
  let orgDropdownData = [];
  if (userData?.role === APP_ROLES.OBOEDIO_ADMIN) {
    orgDropdownData = allOrganizations?.data?.data?.map((org: any) => ({
      value: org?.org_id,
      name: org?.org_name,
    }));
  }
  if (
    userData?.role === APP_ROLES.ORG_ADMIN ||
    userData?.role === APP_ROLES.ORG_USER ||
    userData?.role === APP_ROLES.EXECUTIVE_USER
  ) {
    orgDropdownData = organizations?.map((org: any) => ({
      value: org?.org_id,
      name: org?.org_name,
    }));
  }

  // get state rq key assessment by org_id

  const {
    mutate: getAssessmentsByOrgId,
    isLoading: getAssessmentByOrgIdLoading,
  } = _useMutation({
    queryKey: RQ_KEYS.ASSESSMENTS_BY_ORG_ID,
    onSuccess: (data: any) => {
      console.log("data in onsuccess getAssessmentByOrgId", data);

      setAssessmentsOfOrg(
        // data?.data?.data?.assessmentByOrgId?.map(
        //   ({ assessment_name, assign_assessment_id }: any) =>
        //     ({
        //       value: assign_assessment_id,
        //       name: assessment_name,
        //     } || [])
        // )
        data?.data?.data?.assessmentByOrgId?.map(
          ({ assessment_name, assign_assessment_id }: any) => ({
            value: assign_assessment_id,
            name: assessment_name,
          })
        ) || []
      );

      setOwners(
        data?.data?.data?.ownersData.map((owner: any) => ({
          name: owner.name,
          value: owner.user_id,
        })) || []
      );
console.log("data?.data?.data?.orgLocations",data?.data?.data?.orgLocations)

      setOrgLocations(
       
          data?.data?.data?.orgLocations.map((owner: any) => {
            if (owner) {
              return {
                name: owner,
                value: owner,
              };
            }
            return null;
          }).filter(Boolean) || [] 
      );
    },
  });
  console.log("data>>>>>>>>>>>??",orgLocations)
  console.log("assessmentsOfOrg", assessmentsOfOrg);

  const handleSelectOrganization = ({ value: org_id, name }: any) => {
    setEnableDownloadButton(false);
    setReqData({ ...reqData, org_id, org_name: name });
    getAssessmentsByOrgId({
      url: `${API_ROUTE.GET_ASSESSMENTS_BY_ORG_ID}/${org_id}`,
      requestType: REQUEST_TYPE._GET,
    });
  };

  const { mutate: saveHistoricalData } = _useMutation({
    queryKey: RQ_KEYS.SAVE_HISTORICAL_DATA,
    onSuccess: (data: any) => {
      console.log("data in historical success", data);
    },
  });

  const {
    mutate: getReportByAssessmentId,
    isLoading: getReportByAssessmentIdLoading,
  } = _useMutation({
    queryKey: RQ_KEYS.GET_REPORT_BY_ASSESSMENT_ID,
    onSuccess: (data: any) => {
      console.log("Dynamic Report Type:", dynamicReportType);
      console.log("data?.data?.reportSheetsData>>>",data?.data?.reportSheetsData?.[dynamicReportType]?.length)
      if (data?.data?.reportSheetsData && data?.data?.reportSheetsData?.[dynamicReportType]?.length>0) {
        setReportData(data?.data?.reportSheetsData);
        setEnableDownloadButton(true);
        setNoDataError(false); // Clear error if data is present
      } else {
        setEnableDownloadButton(false);
        setNoDataError(true); // Show error if no data
      }

      saveHistoricalData({
        url: API_ROUTE.SAVE_HISTORICAL_DATA,
        requestType: REQUEST_TYPE._POST,
        requestData: {
          reqData,
          reportData: data?.data?.reportSheetsData,
          dateRange: dateRangeState,
        },
      });
      console.log("data in onsuccess getReport by assessment id", data);
      // here we will get the json to convert to excel
      // setReportData(data?.data?.reportSheetsData);
      setShowDownloadButton(true);
    },
  });

  const { mutate: getReportType, isLoading: getReportTypeLoading } =
    _useMutation({
      queryKey: RQ_KEYS.GET_REPORT_TYPE,
      onSuccess: (data: any) => {
        console.log("data in onsuccess getReport type", data);

        setReportType(
          data?.data?.map(({ report_name }: any) => {
            return {
              name: report_name,
              value: report_name,
            };
          })
        );
      },
    });

  const handleSelectAssessment = ({
    value: assign_assessment_id,
    name,
  }: any) => {
    console.log(
      "assign assessment id in handle select assessment",
      assign_assessment_id
    );
    setEnableDownloadButton(false);
    setReqData({ ...reqData, assign_assessment_id, assessment_name: name });

    setAssessmentData({ name: name, value: assign_assessment_id });

    getReportType({
      url: API_ROUTE.GET_REPORT_TYPE,
      requestType: REQUEST_TYPE._GET,
    });
  };

  console.log("report type state", reportType);

  const handleGenerateReport = () => {
    const requestData = { ...reqData, dateRange: dateRangeState };
    console.log("requestData>>>>>>>" + requestData);
    getReportByAssessmentId({
      url: `${API_ROUTE.GET_REPORT_BY_ASSESSMENT_ID}/${assessmentData?.value}`,
      requestType: REQUEST_TYPE._POST,
      requestData,
    });
  };

  useEffect(() => {
    console.log("Params in useEffect:", params.toString());
  
    if (params.has("assessment_id") && params.has("org_id")) {
      handleSelectOrganization({
        value: params.get("org_id"),
        name: params.get("org_name"),
      });
  
      handleSelectAssessment({
        value: params.get("assessment_id"),
        name: params.get("assessment_name"),
      });
  
      setShowGenerateButton(true);
      setDynamicReportType(params.get("report_type") || "default");
  
      setReqData((prev : any) => {
        const updatedData = {
          ...prev,
          org_name: params.get("org_name") || "",
          assessment_name: params.get("assessment_name") || "",
          // startDate: params.get("startDate") || "",
          // endDate: params.get("endDate") || "",
          org_id: params.get("org_id") || "",
          assessment_id: params.get("assessment_id") || "",
          // compliance_status: params.get("compliance_status")
          //   ? { name: params.get("compliance_status"), value: params.get("compliance_status") }
          //   : prev.compliance_status,
          report_type: params.get("report_type")
            ? { name: params.get("report_type"), value: params.get("report_type") }
            : prev.report_type,
          dateRange: dateRangeState  
        };

        setCompaliance(params.get("compliance_status") || "Compliance Status")
           const locationFromParam = params.get("location");
           setLocation(locationFromParam || "Select location")
        if (locationFromParam) {
          updatedData.location = {
            name: params.get("location"),
            value: params.get("location"),
          };
        }
          const ownerFromParams = params.get("owner")
          setOwner(ownerFromParams || 'Select Owner');
        if ( ownerFromParams && params.get("owner_id")) {
          updatedData.owner = {
            name: params.get("owner"),
            value: params.get("owner_id"),
          };
        }
  
        console.log("Updated ReqData:", updatedData);
        return updatedData;
      });
    } else {
      console.error("Required fields are missing.");
    }
 
  }, [params]);
  

  const navigate = useNavigate();

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  function ShowUserProfile() {
    // console.log(userAuthData)
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS.USER_LOGIN_DETAILS);
    }
  }
  const organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });
  // useEffect(() => {
  //   alert(reqData?.compliance_status?.value);
  // });
  return (
    <React.Fragment>
      <div
        className={`manageAssessmentContainer ${styles.manageAssessmentContainer}`}
      >
        {/* <div className={styles.breadCrumb}>
          <Breadcrumb
            pageName="Reports"
            path={["Dashboard", "Generate Report"]}
          />
        </div> */}

        <div className={styles.manageAssessmentBody}>
          <div className={styles.assessmentheader}>
            <h4>Generate Reports</h4>
            <div className={styles.filtrSearchDiv}>
              <div className={styles.daterangeContainer}>
                <p>Select Date Range:</p>
                <Button
                  // Icon={<UploadIcon fill="#ffffff" />}
                  label={`${new Date(
                    dateRangeState[0]?.startDate
                  ).toDateString()} - ${dateRangeState[0]?.endDate.toDateString()}`}
                  id="selectDateRange"
                  onClick={() => setShowDateRange(!showDateRange)}
                />
              </div>
            </div>

            {showDateRange && (
              <div className={styles.datePicker}>
                <DateRangePickerComp
                  closeHandler={() => setShowDateRange(false)}
                  dateRangeState={dateRangeState}
                  setDateRangeState={setDateRangeState}
                  showDateRange={showDateRange}
                  setShowDateRange={setShowDateRange}
                  showSelectionPreview={true}
                />
              </div>
            )}
          </div>

          <LoadingHoc isLoading={getAssessmentByOrgIdLoading}>
            <div className={styles.assessmentRow}>
              <div className={styles.filtrSearchDiv}>
                <Dropdown
                  label={
                    params.has("org_name")
                      ? (params.get("org_name") as string)
                      : reqData.org_name
                      ? reqData.org_name
                      : "Select Organization"
                  }
                  dropdownData={orgDropdownData ? orgDropdownData : []}
                  onClick={(data: any) => handleSelectOrganization(data)}
                />
              </div>

              {Array.isArray(assessmentsOfOrg) &&
                assessmentsOfOrg?.length > 0 && (
                  <div className={styles.filtrSearchDiv}>
                    <Dropdown
                      label={
                        params.has("assessment_name")
                          ? (params.get("assessment_name") as string)
                          : (
                            <>
                              Select Assessment <sup style={{ color: "var(--redColor)", fontSize:'15px'}}>*</sup>
                            </>
                          )
                      }
                      dropdownData={assessmentsOfOrg}
                      onClick={(data) => {
                        setShowGenerateButton(true);
                        handleSelectAssessment(data);
                      }}
                    />
                  </div>
                )}

              {reportType?.length > 0 && (
                // <LoadingHoc isLoading={getReportTypeLoading}>
                <>
                  <div className={styles.filtrSearchDiv}>
                    <Dropdown
                      label={
                        params.has("report_type")
                          ? (params.get("report_type") as string)
                          : (
                            <>
                              Select Report <sup style={{ color: "var(--redColor)", fontSize:'15px'
                               }}>*</sup>
                            </>
                          )
                      }
                      dropdownData={reportType}
                      onClick={(value: any) => {
                        setShowGenerateButton(true);
                        setEnableDownloadButton(false);
                        setDynamicReportType(value?.name);
                        // setShowDownloadButton(true);
                        setReqData({ ...reqData, report_type: value });
                      }}
                    />
                  </div>

                  {/* <div className={styles.filtrSearchDiv}>
                    <Dropdown
                      label={
                       compaliance ||"Compliance Status"
                      }
                      dropdownData={COMPLIANCE_STATUSES}
                      onClick={(value) => {
                        // alert(value.name);
                        setEnableDownloadButton(false);
                        setReqData((reqData : any)=>({
                          ...reqData,
                          compliance_status: value,
                        }));  
                      }}
                    />
                  </div> */}

                  <div className={styles.filtrSearchDiv}>
                    <Dropdown
                      label={
                     owner || 
                           "Select Owner"
                      }
                      dropdownData={owners}
                      onClick={(value) => {
                        console.log("valuevalue>>>>>>>>>>>>>>>>>>>>>>",params.toString())
                        setEnableDownloadButton(false);
                        setReqData((reqData : any)=>({
                           ...reqData, 
                           owner: value 
                        }));
                      }}
                    />
                  </div>

                  <div className={styles.filtrSearchDiv}>
                    <Dropdown
                      label={
                       location || 
                           "Select Location"
                      }
                      dropdownData={orgLocations.filter((loc:any) => loc?.value !== "" && loc?.value != "null")}
                      onClick={(value) => {
                        setEnableDownloadButton(false);
                        setReqData((reqData : any)=>({ ...reqData, location: value }));
                      }}
                    />
                  </div>
                </>
                // </LoadingHoc>
              )}
            </div>
          </LoadingHoc>
          {Array.isArray(assessmentsOfOrg) &&
            assessmentsOfOrg?.length === 0 && <div>No Assessments found.</div>}

          {noDataError && (
            <div className={styles.errorMessage}>
              *No data found for this report.
            </div>
          )}

          <ExportButton
            reportData={reportData}
            assessmentData={assessmentData}
            getReportByAssessmentId={getReportByAssessmentId}
            showGenerateButton={showGenerateButton}
            showDownloadButton={showDownloadButton}
            getReportByAssessmentIdLoading={getReportByAssessmentIdLoading}
            handleGenerateReport={handleGenerateReport}
            reqData={reqData}
            getAssessmentByOrgIdLoading={getAssessmentByOrgIdLoading}
            enableDownloadButton={enableDownloadButton}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReportsPage;

import { ReactComponent as OrgAdminIcon } from "../assests/svg/org_user.svg";
import { ReactComponent as ReportIcon } from "../assests/svg/assign_assessment.svg";
import { ReactComponent as LocationIcon } from "../assests/svg/map.svg";
import { ReactComponent as AssessmentIcon } from "../assests/svg/assessments.svg";
// import { ReactComponent as DashboardIcon } from "../assests/svg/dashboard_icon.svg";
import { ReactComponent as UserLogo } from "../assests/svg/user_profile.svg";
import { ReactComponent as TaskIcon } from "../assests/svg/task_icon.svg";
import { ReactComponent as TaskIcon2 } from "../assests/svg/task.svg";
import { ReactComponent as QuestionIcon } from "../assests/svg/question_icon.svg";
import { ReactComponent as LogoutIcon } from "../assests/svg/logout.svg";
import { ReactComponent as AdminIcon } from "../assests/svg/admin.svg";
import { ReactComponent as DashBoardIcon } from "../assests/svg/dashboard1.svg";
import { ReactComponent as ReportMainIcon } from "../assests/svg/report.svg";
import { ReactComponent as ManageIcon } from "../assests/svg/manageUser.svg";
import { ReactComponent as DocumentIcon } from "../assests/svg/document.svg";
import { ReactComponent as ReportIcon2 } from "../assests/svg/report.svg";
export const LIMIT = 10;

export const REQUEST_TYPE = {
  _GET: "GET",
  _POST: "POST",
  _PATCH: "PATCH",
  _DELETE: "DELETE",
};

// export const AWS_DOCUMENT_BASE = "https://oboedio-documents.s3.amazonaws.com/";   //Use it for Staging and Local
export const AWS_DOCUMENT_BASE = "https://oboedio-production-data-storage.s3.amazonaws.com/";  //Use it for Production


export const RQ_KEYS = {
  LOGIN:"login",
  AUTH: "auth_token",
  LOADING: "loading",
  USER_DATA: "user_data",
  ORG_ADMINS_LIST: "org_admins_list",
  BRANCH_ADMINS_LIST: "branch_admins_list",
  VENDOR_USERS_LIST_FOR_SUPPORT: "vendor_user_list_for_support",
  ORG_USERS_LIST: "org_users_list",
  VENDOR_USERS_LIST: "vendor_users_list",
  ORG_ADMIN_BY_ID: "org_admin",
  ORG_USER_BY_ID: "org_user",
  UPDATE_USER_DETAILS: "update_user_details",
  TOAST_STATE: "toast_state",
  INDUSTRIES: "industries",
  ASSESSMENT_CATEGORIES: "assessment_categories",
  REGIONS: "regions",
  ASSESSMENT_TYPES: "assessment_types",
  PUBLISHED_ASSESSMENT: "published_assessments",
  DRAFT_ASSESSMENT: "draft_assessments",
  FILTERED_ASSESSMENT_LIST: "filtered_assessments",
  ASSESSMENT_BY_IDS: "assessments_by_id",
  CHAPTER_BY_IDS: "chapter_by_id",
  ALL_PROCESSES: "all_processes",
  ARCHIVE_ASSESSMENT_LIST: "all_archive_assessments",
  ALL_CHAPTERS: "all_chapters",
  NEXT_CHAPTERS: "next_chapter",
  ASSIGNED_CHAPTERS: "assigned_chapter",
  ANSWER_TABLE_BY_CHAPTER: "answer_table_by_chapter",
  DELETE_CHAPTER: "delete_chapter",
  USER_ORGANIZATION: "user_organization",
  ASSESSMENTS_BY_ORG_ID: "assessments_by_org_id",
  GET_REPORT_BY_ASSESSMENT_ID: "get_report_by_assessment_id",
  GET_REPORT_TYPE: "get_report_type",
  ASSESSMENTS_BY_ORG_ID_CATEGORIZED: "assessments_by_org_id_categorized",
  LOCATION_BY_ORGID: "location_by_org_id",
  QUESTIONS: "questions",
  COMPLETE_QUESTIONS: "complete_questions",
  USER_LIST: "user_list",
  REMEDIATION_TASKS: "remediation_task",
  DOCUMENTS: "documents",
  TEMPLATE_SECTION: "TemplateSection",
  TEMPLATE: "SystemTemplate",
  TASK_DOCUMENTS: "task_documents",
  UPLOAD_TEMPLATE: "upload_template",
  UPLOAD_DOCUMENT: "upload_document",
  IMPORT_ASSESSMENT: "import_assessment",
  POST_TABLE_DATA: "post_table_data",
  POST_TABLE_ANSWERS: "post_table_answers",
  UPDATE_TABLE_ANSWER_STATUS: "update_table_answer_status",
  SUBMIT_ASSESSMENT:"submit_assessment",
  SELECT_DOCUMENT: "select_document",
  UPLOAD_VENDOR_DOCUMENT: "upload_vendor_document",
  PENDING_TASKS: "pending_tasks",
  ASSIGNED_BY_ADMIN_PENDING_TASK: "assigned_by_admin_pending_task",
  TO_REVIEW_TASKS: "to_review_tasks",
  IN_PROGRESS_TASKS: "in_progress_tasks",
  ASSIGNED_BY_ADMIN_TO_REVIEW_TASKS: "assigned_by_admin_to_review_tasks",
  COMPLETED_TASKS: "completed_tasks",
  ASSIGNED_BY_ADMIN_COMPLETED_TASKS: "assigned_by_admin_completed_tasks",
  TASKS_COUNT: "tasks_count",
  QUESTION_COUNT: "question_count",
  ASSIGNED_BY_ADMIN_TASK_COUNT: "assigned_by_admin_task_count",
  INPROGRESS_QUESTIONS: "in_progress_questions",
  SELECT_ASSESSMENT: "select_assessment",
  COMPLETED_QUESTIONS: "completed_questions",
  UPDATE_TASK_ASSIGNED_STATUS: "update_task_assigned_status",
  DASHBOARD_ASSESSMENT_DETAILS: "dashboard_assessment_details",
  ADD_COMMENT: "add_comment",
  COMMENTS: "comments",
  TASK_COMMENTS: "task_comments",
  ORGADMIN_LIST: "orgadmin-list",
  ORGUSER_LIST: "orguser-list",
  DASHBOARD_LOCATIONS_DETAILS: "dashboard_locations_details",
  DASHBOARD_BRANCH_DETAILS: "dashboard_branch_details",
  USER_LOGIN_DETAILS: "user_login_details",
  UPDATE_USER_LOGIN_DETAILS: "update_user_login_details",
  ASSESSMENT_QUESTION_COUNT: "assessment_question_count",
  ASSESSMENT_CHAPTER_QUESTION_COUNT: "assessment_chapter_question_count",
  TEMPLATES: "templates",
  ASSESSMENT_BY_ORG_ID: "assessment_by_org_id",
  ORGANIZATIONS: "organizations",
  DOCUMENT_LIB: "document_lib",
  ADMIN_DOCUMENTS: "admin_documents",
  ASSESSMENT_DOCUMENTS: "assessment_documents",
  ORG_DOCUMENTS: "org_documents",
  VENDOR_DOCUMENTS: "vendor_documents",
  UPLOAD_VENDOR_DOCUMENTS: "upload_vendor_documents",
  CLONE_CHAPTER: "clone_chapter",
  SAVE_HISTORICAL_DATA: "save_historical_data",
  HISTORICAL_REPORTS: "historical_reports",
  TASK_QUESTION: "question_task",
  TASKS_ASSESSMENT: "tasks_assessment",
  ASSESSMENT_PDF_DATA: "assessment_pdf_data",
};

export const LOCALSTORAGE = {
  TOKEN: "token",
  CREDS: "token-zip",
};
export const APP_PATHS = {
  LOGIN: "/login",
  FORGET_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  FORGET_PASSWORD_STATUS: "/forgot-password-status",
  RESET_PASSWORD_STATUS: "/reset-password-status",
  RESET_AND_UPDATE_DETAILS: "/reset-update-details",
  ORG_ADMIN_MANAGEMENT: "/administration/organizations",
  GENERATE_REPORTS: "/reports",
  HISTORICAL_REPORTS: "/historical-reports",
  EDIT_ORGANIZATION: "/administration/organizations/edit",
  UN_AUTHENTICATED: "/unauthorised",
  ASSESSMENTS: "/assessing/assessments",
  ORG_ONBOARDING: "/onboarding",
  CHAPTER: "/assessing/assessments/chapters",
  ASSESSMENT_LIST: "/assessing/assessments/assessment-list",
  ASSESSMENT_CHAPTER_LIST: "/assessing/assessments/chapter-list",
  MANAGE_ORGANIZATION: "/manage-organization",
  ORG_DASHBOARD: "/manage/dashboard",
  ORG_DASHBOARD_LOCATIONS: "/org-location",
  ORG_DASHBOARD_BRANCH: "/branch-status",
  ORG_EXECUTIVE: "/dashboard/executive",
  VIEW_ORGANIZATION: "/administration/organizations/view",
  VIEW_USER: "/administration/organizations/user/view",
  VIEW_VENDOR: "/administration/organizations/vendor/view",
  QUESTION_SCREEN: "/assessment/questionaries",
  QUESTION_SUCCESS_SCREEN: "/assessment/success",
  // ASSIGNED_TASKS: "/tasks",
  ASSIGNED_TASKS: "/my-tasks",
  MANAGE_USERS: "/manage-users",
  MANAGE_VENDORS: "/manage-vendors",
  VENDOR_DASHBOARD: "/vendor-dashboard",
  ASSESSMENT_DASHBOARD: "/assessment-dashboard",
  USER_ASSESSMENT: "/user/assessments",
  DOCUMENTS: "/documents",
  VENDOR_DOCUMENTS: "/vendor-documents",
  TEMPLATE_SECTION: "/TemplateSection",
  TEMPLATE: "/Template",
  SUCCESS: "/success",
  ASSIGNED_ASSESSMENTS: "/assigned-assessments",
  ORGADMIN_LIST: "/orgadmin-list",
  ORGUSER_LIST: "/orguser-list",
  BRANCHADMIN_LIST: "/branchadmin-list",
  VENDORUSER_LIST: "/vendoruser-list",
  // TRACK_TASKS: "/track-tasks",
  TRACK_TASKS: "/track-assigned-tasks",
  USER_LOGIN_DETAILS: "/user-login-details",
  SYSTEM_TEMPLATES: "/system-templates",
  QUESTIONAIRE_SUCCESS: "/assessment/questionaire-success",
};

export const APP_ROLES = {
  OBOEDIO_ADMIN: "OBOEDIO_ADMIN",
  ORG_ADMIN: "ORG_ADMIN",
  AUDITOR : 'AUDITOR',
  BRANCH_ADMIN: "BRANCH_ADMIN",
  ORG_USER: "ORG_USER",
  VENDOR_USER: "VENDOR_USER",
  SUPPORT_USER: "SUPPORT_USER",
  EXECUTIVE_USER: "EXECUTIVE_USER",
  ALL: "ALL",
};

export const USER_ROLES = [
  {
    name: "Oboedio Admin",
    key: "OBOEDIO_ADMIN",
    sidenavAccess: [
      {
        section: "Administration",
        Icon: (active: boolean) => (
          <OrgAdminIcon
            style={{
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              fill: "var(--secondaryColor)",
              maxWidth: 20,
            }}
          />
        ),
        options: [
          {
            title: "Organizations",
            path: APP_PATHS.ORG_ADMIN_MANAGEMENT,
            Icon: (active: boolean) => (
              <OrgAdminIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Assessment Management",
        Icon: (active: boolean) => (
          <AssessmentIcon
            style={{
              fill: "var(--secondaryColor)",
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          {
            title: "Assessments",
            path: APP_PATHS.ASSESSMENTS,
            Icon: (active: boolean) => (
              <AssessmentIcon
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Document Library",
        Icon: (active: boolean) => (
          <DocumentIcon
          style={{
            // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
            maxWidth: 18,
            stroke: "#0097ce",
          }}
        />
        ),
        options: [
          {
            title: "Templates",
            path: APP_PATHS.SYSTEM_TEMPLATES,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Reports",
        Icon: (active: boolean) => (
          <ReportIcon2
            style={{
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              stroke: "#0097ce",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          {
            title: "Generate Reports",
            path: APP_PATHS.GENERATE_REPORTS,
            Icon: (active: boolean) => (
              <ReportIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "Historical Reports",
            path: APP_PATHS.HISTORICAL_REPORTS,
            Icon: (active: boolean) => (
              <ReportIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Account",
        Icon: (active: boolean) => (
          <LogoutIcon
            style={{
              fill: "var(--secondaryColor)",
              maxWidth: 10,
            }}
          />
        ),
        options: [
          {
            title: "Logout",
            isClickable: true,
            path: APP_PATHS.ORG_ADMIN_MANAGEMENT,
            Icon: (active: boolean) => (
              <LogoutIcon
                style={{
                  fill: "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Org. Admin",
    key: "ORG_ADMIN",
    sidenavAccess: [
      {
        section: "Dashboard",
        Icon: (active: boolean) => (
          <DashBoardIcon style={{ maxWidth: "18px", stroke: "#0097ce" }} />
        ),
        options: [
          {
            title: "Dashboard",
            path: APP_PATHS.ORG_DASHBOARD,
            Icon: (active: boolean) => (
              <DashBoardIcon
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Manage",
        Icon: (active: boolean) => (
          <ManageIcon style={{ maxWidth: "18px", stroke: "#0097ce" }} />
        ),
        options: [
          // {
          //   title: "Dashboard",
          //   path: APP_PATHS.ORG_DASHBOARD,
          //   Icon: (active: boolean) => (
          //     <DashBoardIcon
          //       style={{
          //         fill: "var(--primaryColor)",
          //         // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
          //         maxWidth: 18,
          //       }}
          //     />
          //   ),
          // },
          {
            title: "Organization",
            path: APP_PATHS.MANAGE_ORGANIZATION,
            Icon: (active: boolean) => (
              <LocationIcon
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "Users",
            path: APP_PATHS.MANAGE_USERS,
            Icon: (active: boolean) => (
              <UserLogo
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 16,
                }}
              />
            ),
          },
          {
            title: "Vendors",
            path: APP_PATHS.MANAGE_VENDORS,
            Icon: (active: boolean) => (
              <UserLogo
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 16,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Assessments",
        Icon: (active: boolean) => (
          <AssessmentIcon
            style={{
              fill: "#0097ce",
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              maxWidth: 18,
              // stroke: "#0097ce",
            }}
          />
        ),
        options: [
          {
            title: "Assessments",
            // path: APP_PATHS.ASSESSMENT_LIST,
            path: APP_PATHS.ASSESSMENT_DASHBOARD,
            Icon: (active: boolean) => (
              <AssessmentIcon
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          // {
          //   title: "Tasks",
          //   path: APP_PATHS.ASSIGNED_TASKS,
          //   Icon: (active: boolean) => (
          //     <TaskIcon
          //       style={{
          //         // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
          //         fill: "var(--primaryColor)",
          //         maxWidth: 18,
          //       }}
          //     />
          //   ),
          // },
        ],
      },
      {
        section: "Tasks",
        Icon: (active: boolean) => (
          <TaskIcon2
            style={{
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              fill: "var(--primaryColor)",
              maxWidth: 19,
              stroke: "#0097ce",
            }}
          />
        ),
        options: [
          
          {
            title: "My Tasks",
            path: APP_PATHS.ASSIGNED_TASKS,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "Assigned Tasks",
            path: APP_PATHS.TRACK_TASKS,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          }
        ],
      },
      {
        section: "Document Library",
        Icon: (active: boolean) => (
          <DocumentIcon
            style={{
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              maxWidth: 18,
              stroke: "#0097ce",
            }}
          />
        ),
        options: [
          {
            title: "Documents",
            path: APP_PATHS.DOCUMENTS,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "Templates",
            path: APP_PATHS.TEMPLATE,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Reports",
        Icon: (active: boolean) => (
          <ReportIcon2 style={{ maxWidth: "18px", stroke: "#0097ce" }} />
        ),
        options: [
          {
            title: "Generate Reports",
            path: APP_PATHS.GENERATE_REPORTS,
            Icon: (active: boolean) => (
              <ReportIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "Historical Reports",
            path: APP_PATHS.HISTORICAL_REPORTS,
            Icon: (active: boolean) => (
              <ReportIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Account",
        Icon: (active: boolean) => (
          <LogoutIcon
            style={{
              fill: "#0097ce",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          {
            title: "Logout",
            isClickable: true,
            path: APP_PATHS.ORG_ADMIN_MANAGEMENT,
            Icon: (active: boolean) => (
              <LogoutIcon
                style={{
                  fill: "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Branch Admin",
    key: "BRANCH_ADMIN",
    parent: "ORG_ADMIN",
    sidenavAccess: [
      {
        section: "My To Do",
        Icon: (active: boolean) => (
          <TaskIcon2
            style={{
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              fill: "var(--secondaryColor)",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          // {
          //   title: "Questions",
          //   path: APP_PATHS.ASSIGNED_ASSESSMENTS,
          //   Icon: (active: boolean) => (
          //     <AssessmentIcon
          //       style={{
          //         // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
          //         fill: "var(--primaryColor)",
          //         maxWidth: 18,
          //       }}
          //     />
          //   ),
          // },
          
          {
            title: "My Assessments",
            path: APP_PATHS.ASSESSMENT_DASHBOARD,
            Icon: (active: boolean) => (
              <AssessmentIcon
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
          {
            title: "My Tasks",
            path: APP_PATHS.ASSIGNED_TASKS,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Assigned Tasks",
        Icon: (active: boolean) => (
          <AssessmentIcon
            style={{
              fill: "var(--secondaryColor)",
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          {
            title: "Tasks",
            path: APP_PATHS.TRACK_TASKS,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Document Library",
        Icon: (active: boolean) => (
          <DocumentIcon
            style={{
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              stroke: "#0097ce",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          {
            title: "Documents",
            path: APP_PATHS.DOCUMENTS,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
          {
            title: "Templates",
            path: APP_PATHS.TEMPLATE,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Account",
        Icon: (active: boolean) => (
          <LogoutIcon
            style={{
              fill: "var(--secondaryColor)",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          {
            title: "Logout",
            isClickable: true,
            path: APP_PATHS.ORG_ADMIN_MANAGEMENT,
            Icon: (active: boolean) => (
              <LogoutIcon
                style={{
                  fill: "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Org. User",
    key: "ORG_USER",
    parent: "ORG_ADMIN",
    sidenavAccess: [
      {
        section: "My To Do",
        Icon: (active: boolean) => (
          <AssessmentIcon
            style={{
              fill: "var(--secondaryColor)",
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          {
            title: "My Assessments",
            path: APP_PATHS.ASSESSMENT_DASHBOARD,
            Icon: (active: boolean) => (
              <AssessmentIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "My Tasks",
            path: APP_PATHS.ASSIGNED_TASKS,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Document Library",
        Icon: (active: boolean) => (
          <DocumentIcon
            style={{
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              maxWidth: 18,
              stroke: "#0097ce",
            }}
          />
        ),
        options: [
          {
            title: "Documents",
            path: APP_PATHS.DOCUMENTS,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "Templates",
            path: APP_PATHS.TEMPLATE,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Reports",
        Icon: (active: boolean) => (
          <ReportIcon2 style={{ maxWidth: "18px", stroke: "#0097ce" }} />
        ),
        options: [
          {
            title: "Generate Reports",
            path: APP_PATHS.GENERATE_REPORTS,
            Icon: (active: boolean) => (
              <ReportIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "Historical Reports",
            path: APP_PATHS.HISTORICAL_REPORTS,
            Icon: (active: boolean) => (
              <ReportIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Account",
        Icon: (active: boolean) => (
          <LogoutIcon
            style={{
              fill: "#0097ce",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          {
            title: "Logout",
            isClickable: true,
            path: APP_PATHS.ORG_ADMIN_MANAGEMENT,
            Icon: (active: boolean) => (
              <LogoutIcon
                style={{
                  fill: "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Vendor User",
    key: "VENDOR_USER",
    parent: "ORG_ADMIN",
    sidenavAccess: [
      {
        section: "My To Do",
        Icon: (active: boolean) => (
          <AssessmentIcon
            style={{
              fill: "var(--secondaryColor)",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          // {
          //   title: "Questions",
          //   path: APP_PATHS.ASSIGNED_ASSESSMENTS,
          //   Icon: (active: boolean) => (
          //     <QuestionIcon
          //       style={{
          //         // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
          //         fill: "var(--primaryColor)",
          //         maxWidth: 18,
          //       }}
          //     />
          //   ),
          // },
          {
            title: "My Assessments",
            path: APP_PATHS.ASSESSMENT_DASHBOARD,
            Icon: (active: boolean) => (
              <AssessmentIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "My Tasks",
            path: APP_PATHS.ASSIGNED_TASKS,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Document Library",
        Icon: (active: boolean) => (
          <DocumentIcon
            style={{
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              stroke: "#0097ce",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          {
            title: "Documents",
            path: APP_PATHS.VENDOR_DOCUMENTS,
            Icon: (active: boolean) => (
              <TaskIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          // {
          //   title: "Templates",
          //   path: APP_PATHS.TEMPLATE,
          //   Icon: (active: boolean) => (
          //     <TaskIcon
          //       style={{
          //         // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
          //         fill: "var(--primaryColor)",
          //         maxWidth: 18,
          //       }}
          //     />
          //   ),
          // },
        ],
      },
      {
        section: "Account",
        Icon: (active: boolean) => (
          <LogoutIcon
            style={{
              fill: "var(--secondaryColor)",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          {
            title: "Logout",
            isClickable: true,
            path: APP_PATHS.ORG_ADMIN_MANAGEMENT,
            Icon: (active: boolean) => (
              <LogoutIcon
                style={{
                  fill: "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Executive User",
    key: "EXECUTIVE_USER",
    parent: "ORG_ADMIN",
    sidenavAccess: [
      // {
      //   section: "Manage",
      //   Icon: (active: boolean) => (
      //     <ManageIcon style={{ maxWidth: "18px", stroke: "#0097ce" }} />
      //   ),
      //   options: [
      //     {
      //       title: "Dashboard",
      //       path: APP_PATHS.ORG_DASHBOARD,
      //       Icon: (active: boolean) => (
      //         <DashBoardIcon
      //           style={{
      //             fill: "var(--primaryColor)",
      //             // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
      //             maxWidth: 18,
      //           }}
      //         />
      //       ),
      //     },
      //   ],
      // },
      {
        section: "Dashboard",
        Icon: (active: boolean) => (
          <DashBoardIcon style={{maxWidth: "18px", stroke: "#0097ce" }} />
        ),
        options: [
          {
            title: "Dashboard",
            path: APP_PATHS.ORG_DASHBOARD,
            Icon: (active: boolean) => (
              <DashBoardIcon
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Reports",
        Icon: (active: boolean) => (
          <ReportIcon2 style={{ maxWidth: "18px", stroke: "#0097ce" }} />
        ),
        options: [
          {
            title: "Generate Reports",
            path: APP_PATHS.GENERATE_REPORTS,
            Icon: (active: boolean) => (
              <ReportIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "Historical Reports",
            path: APP_PATHS.HISTORICAL_REPORTS,
            Icon: (active: boolean) => (
              <ReportIcon
                style={{
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  fill: "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Account",
        Icon: (active: boolean) => (
          <LogoutIcon
            style={{
              fill: "#0097ce",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          {
            title: "Logout",
            isClickable: true,
            path: APP_PATHS.ORG_ADMIN_MANAGEMENT,
            Icon: (active: boolean) => (
              <LogoutIcon
                style={{
                  fill: "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Oboedio Support",
    key: "SUPPORT_USER",
    sidenavAccess: [
      {
        section: "ADMINISTRATION",
        options: [
          {
            title: "Org Admin",
            path: APP_PATHS.ORGADMIN_LIST,
            Icon: (active: boolean) => (
              <AdminIcon
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "Org User",
            path: APP_PATHS.ORGUSER_LIST,
            Icon: (active: boolean) => (
              <AdminIcon
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "Branch Admin",
            path: APP_PATHS.BRANCHADMIN_LIST,
            Icon: (active: boolean) => (
              <AdminIcon
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          {
            title: "Vendor User",
            path: APP_PATHS.VENDORUSER_LIST,
            Icon: (active: boolean) => (
              <AdminIcon
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
        ],
      },
      {
        section: "Account",
        options: [
          {
            title: "Logout",
            isClickable: true,
            path: APP_PATHS.ORG_ADMIN_MANAGEMENT,
            Icon: (active: boolean) => (
              <LogoutIcon
                style={{
                  fill: "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Auditor",
    key: "AUDITOR",
    parent: "ORG_ADMIN",
    sidenavAccess: [
      {
        section: "Assessments",
        Icon: (active: boolean) => (
          <AssessmentIcon
            style={{
              fill: "#0097ce",
              // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
              maxWidth: 18,
              // stroke: "#0097ce",
            }}
          />
        ),

        options: [
          {
            title: "Assessments",
            // path: APP_PATHS.ASSESSMENT_LIST,
            path: APP_PATHS.ASSESSMENT_DASHBOARD,
            Icon: (active: boolean) => (
              <AssessmentIcon
                style={{
                  fill: "var(--primaryColor)",
                  // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
                  maxWidth: 18,
                }}
              />
            ),
          },
          // {
          //   title: "Tasks",
          //   path: APP_PATHS.ASSIGNED_TASKS,
          //   Icon: (active: boolean) => (
          //     <TaskIcon
          //       style={{
          //         // fill: active ? "var(--whiteColor)" : "var(--primaryColor)",
          //         fill: "var(--primaryColor)",
          //         maxWidth: 18,
          //       }}
          //     />
          //   ),
          // },
        ],
      },
      {
        section: "Account",
        Icon: (active: boolean) => (
          <LogoutIcon
            style={{
              fill: "#0097ce",
              maxWidth: 18,
            }}
          />
        ),
        options: [
          {
            title: "Logout",
            isClickable: true,
            path: APP_PATHS.ORG_ADMIN_MANAGEMENT,
            Icon: (active: boolean) => (
              <LogoutIcon
                style={{
                  fill: "var(--primaryColor)",
                  maxWidth: 10,
                }}
              />
            ),
          },
        ],
      },
    ],
  },
];

export const ASSESSMENT_MODAL_NAMES = {
  ASSESSMENT: "ASSESSMENT",
  DELETE_ASSESSMENT: "DELETE_ASSESSMENT",
  CHAPTER: "CHAPTER",
  DELETE_CHAPTER: "DELETE_CHAPTER",
  DELETE_ENTRY: "DELETE_ENTRY",
  DELETE_ARTICLE: "DELETE_ARTICLE",
  DELETE_QUESTION: "DELETE_QUESTION",
  DELETE_TASK: "DELETE_TASK",
  ARTICLE: "ARTICLE",
  VIEW_ARTICLE: "VIEW_ARTICLE",
  QUESTION: "QUESTION",
  TASK: "TASK",
  UNASSIGN_USER_ASSESSMENT: "UNASSIGN_USER_ASSESSMENT",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",
  DELETE_PROCESS: "DELETE_PROCESS",
  IMPORT_ASSESSMENT: "IMPORT_ASSESSMENT",
};

export const ONBOARDING_MODAL_NAMES = {
  DELETE_LOCATION: "DELETE_LOCATION",
};

export const QUESTION_SCREEN_MODAL_NAMES = {
  ASSIGN_QUESTION: "ASSIGN_QUESTION",
  ASSIGN_TASK: "ASSIGN_TASK",
  ASSIGN: "ASSIGN",
  SUBMIT_ASSESSMENT: "SUBMIT_ASSESSMENT",
};

export const ORGANIZATION_MODAL_NAMES = {
  MANAGE_ORGANIZATION: "MANAGE_ORGANIZATION",
  UPDATE_USER: "UPDATE_USER",
};

export const PROFILE_STATUS = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
};

export const STEP_TABS = [
  {
    title: "Organization Details",
    subTitle: "Step 1",
    status: "In progress",
  },
  {
    title: "Office Location",
    subTitle: "Step 2",
    status: "Pending",
  },
  {
    title: "Select Industry",
    subTitle: "Step 3",
    status: "Pending",
  },
  {
    title: "Select Assessment",
    subTitle: "Step 4",
    status: "Pending",
  },
];

export const ASSESSMENT_CATEGORIES = [
  {
    label: "Standard",
    value: "Standard",
  },
  {
    label: "Federal",
    value: "Federal",
  },
  {
    label: "US Assessments",
    value: "US Assessments",
  },
  {
    label: "European Union",
    value: "European Union",
  },
  {
    label: "World Wide",
    value: "World Wide",
  },
  {
    label: "Privacy",
    value: "Privacy",
  },
  {
    label: "Global Assessments",
    value: "Global Assessments",
  },
];

export const ASSIGN_ASSESSMENT_STATUS = {
  ASSIGNED: "Assigned",
  UN_ASSIGNED: "Unassigned",
  PENDING: "Pending",
  CLOSED: "Closed",
  COMPLETED: "Completed",
};
export const ASSESSMENT_TYPE = {
  ASSESSMENT: "Assessment",
  QUESTION: "Question",
  TASK: "Task",
  CHAPTER: "Chapter",
};

// export const LOCALSTORAGE_ITEMS = {
//   CREDS: "oboedio-creds",
// };


export const ASSIGNED_STATUS = {
  IN_PROGRESS: "In_Progress",
  COMPLETED: "Completed",
  ASSIGNED: "Assigned",
  UNASSIGNED: "Unassigned",
  UNDER_REVIEW: "Under_Review",
};

export const ASSESSMENT_STATUS = {
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
};

export const COMPLIANCE_STATUSES = [
  {
    name: "ALL",
    value: "ALL",
  },

  {
    name: "Compliant",
    value: "compliant",
  },
  {
    name: "Non Compliant",
    value: "non-compliant",
  },
];

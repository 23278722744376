import * as Yup from "yup";
const ResetAndUpdateDetailsSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("This field is required")
    .matches(
      /^[a-zA-Z]*$/,
      "Please enter a valid name without numbers or special characters."
    ),
  last_name: Yup.string().matches(
    /^[a-zA-Z]*$/,
    "Please enter a valid name without numbers or special characters."
  ),
  // password: Yup.string()
  //   .required("This field is required")
  //   .min(8, "Password must be atleast of 8 letters"),
  password: Yup.string().required("Password is required")
  .min(8, "Password must be at least 8 characters long")
  .matches(
    /^(?=.*[0-9])(?=.*[!@#$%^&*()])[a-zA-Z0-9!@#$%^&*()]+$/,
    "Password must contain at least one special character and one number"
  )
  .test(
    "case-one",
    "Password must contain at least one uppercase letter",
    (value) => /[A-Z]/.test(value)
  )
  .test(
    "case-two",
    "Password must contain at least one lowercase letter",
    (value) => /[a-z]/.test(value)
  ),

  confirm_password: Yup.string()
    .required("This field is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),

  // vendor_org_name: Yup.string(),
});

const ResetAndUpdateVendorDetailsSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("This field is required")
    .matches(
      /^[a-zA-Z]*$/,
      "Please enter a valid name without numbers or special characters."
    ),
  last_name: Yup.string().matches(
    /^[a-zA-Z]*$/,
    "Please enter a valid name without numbers or special characters."
  ),
  vendor_org_name: Yup.string()
  .required("This field is required")
  .matches(
    /^[A-Za-z]+([A-Za-z\s&_\-\.]+)*$/,
    "Please enter a valid Product name."
  ),
  product_name:Yup.string()
  .required("This field is required")
  .matches(
    /^[A-Za-z]+([A-Za-z\s&_\-\.]+)*$/,
    "Please enter a valid Product name."
  ),
product_website:Yup.string()
.required("This field is required")
.matches(
  /^(https?:\/\/)?(www\.)?([\w-]+(\.[\w-]+)+)(\/[\w-.~:?#[\]@!$&'()*+,;=%]*)?$/,
  "Please enter a valid website url."
),
  password: Yup.string().required("Password is required")
  .min(8, "Password must be at least 8 characters long")
  .matches(
    /^(?=.*[0-9])(?=.*[!@#$%^&*()])[a-zA-Z0-9!@#$%^&*()]+$/,
    "Password must contain at least one special character and one number"
  )
  .test(
    "case-one",
    "Password must contain at least one uppercase letter",
    (value) => /[A-Z]/.test(value)
  )
  .test(
    "case-two",
    "Password must contain at least one lowercase letter",
    (value) => /[a-z]/.test(value)
  ),

  confirm_password: Yup.string()
    .required("This field is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),

  // vendor_org_name: Yup.string(),
});

const PersonalDetailsValue = {
  // first_name: "",
  // last_name: "",
  password: "",
  confirm_password: "",
};

export { ResetAndUpdateDetailsSchema, PersonalDetailsValue, ResetAndUpdateVendorDetailsSchema };

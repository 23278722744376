import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { APP_PATHS, REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import {
  useApiQuery,
  _getQueryState,
  _useMutation,
} from "../../../../services/useAxiosQuery";
import { Button } from "../../../../common/components";
import ProgressBar from "../../../../common/components/ProgressBar";
import Tooltip from "../../../../common/Parts/ToolTip";
import PieChart from "../../../../common/components/PieChart";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import BarChart from "../../../../common/components/BarChart";
import { Breadcrumb } from "../../../../common/Parts";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../../assests/svg/map.svg";
import { ReactComponent as BackIcon } from "../../../../assests/svg/backArrow.svg";
import LoadingHoc from "../../../../common/components/LoadingHoc";

const Executive = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSuccess = () => {
    queryClient.refetchQueries({
      queryKey: RQ_KEYS.DASHBOARD_LOCATIONS_DETAILS,
    });
  };

  const { mutateAsync } = _useMutation({ onSuccess });
  //   const [LocationData , setLocationData] = useState([])
  const [LocationData, setLocationData] = useState<{ org_name: string }[]>([]);

  const [orgName, setorgName] = useState("");
  const storedAccessData: any = localStorage.getItem("accessdata");
  const accessDataObject = JSON.parse(storedAccessData);
  let orgid = accessDataObject.org_id;
  let assessmentid = accessDataObject.Assesment_id;
  let assessmentName = accessDataObject.AssesmentName;
  const [HandleLoader, setHandleLoader] = useState(true);
  console.log(assessmentName);

  let PercentageData: any[] = [];
  let AssesmentNameList: any[] = [];
  const colors = [
    "#0097ce",
    "#55a8d6",
    "#7eb9df",
    "#a1cae7",
    "#c1dbef",
    "#e0edf7",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(orgid, assessmentid);
        const result = await mutateAsync({
          url: API_ROUTE.GET_DASBOARD_LOCATIONS,
          requestType: REQUEST_TYPE._POST,
          requestData: {
            org_id: Number(orgid),
            assessment_id: Number(assessmentid),
          },
        });

        setLocationData(result.data);
      } catch (error) {
        console.error("Mutation error:", error);
      } finally {
        setHandleLoader(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (LocationData?.length > 0) {
      setorgName(LocationData[0].org_name);
      // setHandleLoader(false);
    }
  }, [LocationData]);

  function ShowBranchAssesmentData(data: any) {
    accessDataObject.branch_name = data.branch_name;
    accessDataObject.branch_id = data.olr_id;
    localStorage.setItem("accessdata", JSON.stringify(accessDataObject));
    navigate(APP_PATHS.ORG_DASHBOARD_BRANCH);
  }

  Chart.register(CategoryScale);

  const data = {
    labels: AssesmentNameList,
    datasets: [
      {
        label: "Assessments Completetion Status(%)",
        data: PercentageData,
        backgroundColor: colors,
        borderWidth: 2,
      },
    ],
  };

  return (
    <LoadingHoc isLoading={HandleLoader}>
      <div className={styles.dashboard}>
        <div className={styles.breadcrumb}>
          <Button
            label=""
            id="backBtn"
            color="var(--blackColor)"
            background="transparent"
            fontSize="var(--secondaryFont)"
            onClick={() => {
              navigate(-1);
            }}
            border="none"
            Icon={
              <BackIcon
                className="svg_image_icon"
                fill="#0097ce"
                style={{ width: "30px" }}
              />
            }
          />
          <div className={styles.breadcrumbcontainer}>
            <Breadcrumb
              pageName=" Manage Dashboard"
              showBtn="true"
              // org_name={orgName}
            />

            {/* <p>DashBoard</p> */}
          </div>
        </div>

        <div className={styles.dashExecutiveContainer}>
          <div className={styles.dashExecutiveBody}>
            <p>{assessmentName}</p>

            <div className={styles.dashExecutiveGraph}>
              <div className={styles.dashExecGraphCircle}>
                <PieChart title="GLOBAL COMPLIANCE" chartData={data} />
              </div>
              <div className={styles.dashExecGraphBar}>
                <BarChart
                  title="RETROSPECTIVE GLOBAL COMPLIANCE"
                  chartData={data}
                />
              </div>
            </div>
            <div className={styles.dashExecGraphBarFull}>
              <h4>Locations</h4>
              <div className={styles.dashExecArea}>
                {LocationData ? (
                  LocationData.map((value: any, index: number) => {
                    const completionPercentage = (
                      (value.completed_question_count /
                        value.total_question_count) *
                      100
                    ).toFixed(2);
                    PercentageData.push(completionPercentage);
                    AssesmentNameList.push(value.branch_name);

                    return (
                      <div
                        className={styles.card}
                        key={index}
                        onClick={() => {
                          ShowBranchAssesmentData(LocationData[index]);
                        }}
                      >
                        <h3>{value.branch_name}</h3>
                        <span>{value.country && value.country != "null" ? value.country : "N/A"}</span>
                        <Tooltip text={completionPercentage + "%"}>
                          <ProgressBar
                            fullStatus={
                              (value?.completed_question_count /
                                value?.total_question_count) *
                                100 ==
                              100
                                ? true
                                : false
                            }
                            progress={`${
                              (value?.completed_question_count /
                                value?.total_question_count) *
                              100
                            }%`}
                          />{" "}
                        </Tooltip>
                      </div>
                    );
                  })
                ) : (
                  <p>No Assessments added yet</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingHoc>
  );
};

export default React.memo(Executive);
